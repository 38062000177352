<template>
  <section class="mainText ">
    <b-container fluid="xl">
        <b-row id="main1" class="maintText__row">
            <b-col cols="12" class="pb-5 side-gap">
                <h2 style="mainText__mainName--maintxtx">
                    Overprotectiveness
                </h2>
                <p class="hashText">- azaz a túlzott féltés</p>
            </b-col>
        </b-row>
        <b-row class="maintText__row">
            <b-col cols="12" lg="12">
            <div class="maintText__txt-content pb-lg-5 pb-2">
                <p class="text">
                Ki ne hallott – esetleg tapasztalt – volna a helikopter szülőséghez kapcsolódó nehézségekről. Az overprotectiveness, azaz a túlzott féltés és kontroll fogalmával gyakran találkozunk a gyerekneveléssel kapcsolatban, azonban kevesebbet foglalkozunk ennek a viselkedésnek a párkapcsolatban való toxikus megjelenésével. Az overprotectivenesst gyakran összetévesztik a törődéssel és aggodalommal, valójában azonban a pszichológiai bántalmazás egyik formájáról van szó, amely magában foglalja az áldozat tevékenységei és kapcsolatai feletti túlzott kontrollt, a magánéletük megsértését és a bizalom hiányát. Az érzelmi manipuláció ezen formája károsíthatja az áldozat autonómiáját és önbecsülését, bizalmatlansághoz, haraghoz és bizonytalansághoz vezethet, ami káros lehet a pár kötődésére, mérgező párkapcsolati dinamikához vezethet, illetve súlyos esetekben verbális és fizikai erőszak követheti. </p>

                <p class="text">
                Sokszor nehezen észrevehető ez a viselkedés, mivel az áldozatok számára összemosódhat a határ az őszinte törődés kimutatása és felettük való kontroll gyakorlása között. Mint az érzelmi bántalmazás sok formája, az overproteciveness is fokozatosan alakul ki, tovább nehezítve a felismerését. Éppen ezért fontos megérteni néhány gyakori jelet és viselkedést, amelyek overprotectivenessre utalnak. <br>
                -   bűntudat keltés: „Mindig felidegesítesz/dühössé teszel.” „Tönkreteszed a kedvem/napom.”<br>
                -	téged hibáztat az ő érzelmeiért és viselkedéséért<br>
                -	a bizalom hiánya: naponta többször becsekkol, hogy hol és kivel vagy, mit csinálsz, elvárja, hogy minden percedről beszámolj, ellenőrzi a telefonodat<br>
                -	kontroll: azon kapod magad, hogy alig találkozol, beszélsz a barátaiddal, mert a párod ellenzi, a párod kezeli az anyagi döntéseidet, beleszól abba, hogy mit viselsz és hogyan sminkelsz<br>
                -	nem tartja tiszteletben a határaidat, a féltékenység és bizalmatlanság mindent áthat<br>
                -	hiányzik a kapcsolatotokból a partnerségen alapuló, egészséges kommunikáció
                </p>
            </div>
            </b-col>
            <b-col cols="12" lg="12" class="order-4 order-lg-1">
              <img src="images/overprotectiveness.jpg" class="w-100 margin-top-sm-44 mb-4"/>
          </b-col>
        </b-row>
    </b-container>
    <Articles :isFirst="false"/>
  </section>
</template>
<script>
import Articles from '../components/Articles.vue'

export default {
  components: {
    Articles
  },
  mounted(){

    this.setMetaTags({        
        title: "Overprotectiveness", 
        desc: "Az overprotectiveness, azaz a túlzott féltés és kontroll fogalmával gyakran találkozunk a gyerekneveléssel kapcsolatban, azonban kevesebbet foglalkozunk ennek a viselkedésnek a párkapcsolatban való toxikus megjelenésével.", 
        keywords: null, 
        ogType: 'website', 
        ogTitle: "Overprotectiveness",
        ogImage: null, 
        ogImageWidth: null, 
        ogImageHeight: null, 
        ogDesc: "Az overprotectiveness, azaz a túlzott féltés és kontroll fogalmával gyakran találkozunk a gyerekneveléssel kapcsolatban, azonban kevesebbet foglalkozunk ennek a viselkedésnek a párkapcsolatban való toxikus megjelenésével."        
    })
  }
}
</script>